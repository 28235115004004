import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, message, Popconfirm, Select, Table, TableColumnsType, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, DeleteOutlined, FormOutlined } from '@ant-design/icons'
import { useDeleteCheckIn, useListCheckin } from '../hooks/checkinCustomHook'
import { CheckIn } from '../domain/checkins/model'
import Search from 'antd/es/input/Search'
import { useListEvent } from '../hooks/eventCustomHook'
const { Text } = Typography

function CheckInScreen() {
  const { list: eventList } = useListEvent()
  const { list, loading: loadingList, Refresh } = useListCheckin()
  const { Delete } = useDeleteCheckIn()
  const [event, setEvent] = useState({
    value: '',
    label: '',
  })
  const [data, setData] = useState(list)
  const [loading, setLoading] = useState(loadingList)

  const confirm = (data: CheckIn) => {
    setLoading(true)
    Delete(data).then(() => {
      return Refresh()
    }).catch((err) => {
      message.error(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleSelectEvent = (id: string) => {
    const event = eventList.find((e) => e.id === id)
    if (event) {
      setEvent({
        value: event.id,
        label: event.name,
      })
    } else {
      setEvent({
        value: '',
        label: '',
      })
    }
  }

  useEffect(() => {
    setData(list.filter((e: CheckIn) => event.value === '' || e.event.id === event.value))
  }, [event, list])

  const columns: TableColumnsType<CheckIn> = [
    {
      width: '8rem',
      title: 'Play Data',
      dataIndex: 'playDate',
      key: 'playDate',
      render: (playDate: Date, data: CheckIn) => playDate ? `${playDate.getDate()}/${playDate.getMonth() + 1}/${playDate.getFullYear()}` : '',
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.playDate.getTime() - b.playDate.getTime(),
        // multiple: 10
      },
    },
    {
      width: '16rem',
      title: 'Events',
      dataIndex: 'event',
      key: 'event',
      sortOrder: 'ascend',
      render: (_: any, data: CheckIn) => data.event.name,
    },
    {
      title: 'Fullname',
      dataIndex: 'player',
      key: 'player',
      render: (_: any, data: CheckIn) => data.player ? data.player.getFullname() : '',
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.player.getFullname().localeCompare(b.player.getFullname(), 'th'),
        // multiple: 7,
      },
    },
    {
      title: 'Hole',
      dataIndex: 'hole',
      key: 'hole',
      width: '6rem',
      render: (text: number) => `Hole ${text}`,
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.hole - b.hole,
        // multiple: 9
      },
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      width: '6rem',
      render: (group: number) => `Group ${group}`,
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.group - b.group,
        // multiple: 8
      },
    },
    {
      title: 'Caddie',
      dataIndex: 'caddie',
      key: 'caddie',
      width: '8rem',
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.caddie.localeCompare(b.caddie, 'th'),
        // multiple: 6
      },
    },
    {
      title: 'Time',
      render: (_: any, data: CheckIn) => data.playDate ? `${data.playDate.getHours()}:${data.playDate.getMinutes().toString().padStart(2, '0')}` : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '8rem',
      render: (status: number) => status === 1 ? (<Text type="warning" strong><ClockCircleFilled /> Pending</Text>) :
        status === 2 ? (<Text style={{ color: '#1677FF', fontWeight: 'bold' }}><CheckCircleFilled /> Check In</Text>) : <Text type="secondary" strong><CloseCircleFilled /> Cancelled</Text>,
      sorter: {
        compare: (a: CheckIn, b: CheckIn) => a.status - b.status,
        multiple: 5
      }
    },
    {
      title: 'Tools',
      dataIndex: '',
      key: '',
      width: '8rem',
      render: (text: string, data: CheckIn) => (
        <Flex justify='space-between'>
          <Link to={`/events/checkins/${data.id}`}>
            <Button type='primary'><FormOutlined /></Button>
          </Link>
          <Popconfirm
            title={`Are you sure you want to delete this record?`}
            onConfirm={() => {
              confirm(data)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type='primary' danger><DeleteOutlined /></Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ]

  const onSearch = (search: string) => {
    if (search.length) {
      setData(list.filter((e: CheckIn) => e.player.fullname.toLowerCase().includes(search.toLowerCase())))
    } else {
      setData(list)
    }
  }

  useEffect(() => {
    if (!loadingList) {
      setLoading(false)
      setData(list)
    }
  }, [loadingList, list])

  return (
    <>
      <Flex justify='space-between'>
        <Flex>
          <Form.Item>
            <Select
              style={{ minWidth: 300 }}
              showSearch
              placeholder="All Event"
              value={event.value}
              defaultValue={event.value}
              optionFilterProp="label"
              onChange={handleSelectEvent}
              options={[
                { value: '', label: 'All Event' },
                ...eventList.map((e) => ({ value: e.id, label: e.name }))
              ]}
            />
          </Form.Item>
        </Flex>
        <Flex gap={10} justify={'right'}>
          <Form.Item>
            <Search placeholder="input search text" onSearch={onSearch} style={{ minWidth: 300 }} />
          </Form.Item>
          <Link to='/events/checkins/create'>
            <Button type='primary'>Add New</Button>
          </Link>
        </Flex>
      </Flex>

      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        pagination={{ defaultPageSize: 10 }}
        showHeader={true}
        showSorterTooltip={{ target: 'sorter-icon' }}
        bordered
      ></Table >

    </>
  )
}

export default CheckInScreen