import { collection, doc, Firestore, getDoc, getDocs, query } from "firebase/firestore";
import { Player } from "../domain/players/model";
import PlayerRepository from "../domain/players/repository";
import { DataRepo } from "./data";
import NotificationRepo from "./notifications";
import { Functions, httpsCallable } from "firebase/functions";


export default class PlayerRepo implements PlayerRepository {
    functions: Functions
    firestore: Firestore
    dataRepo: DataRepo
    notiRepo: NotificationRepo
    constructor(firestore: Firestore, functions: Functions, dataRepo = new DataRepo(firestore), notiRepo = new NotificationRepo(firestore)) {
        this.firestore = firestore
        this.dataRepo = dataRepo
        this.notiRepo = notiRepo
        this.functions = functions
    }

    async List(input: { search: string; page: number; totalPerPage: number; status: string; }): Promise<Player[]> {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "players"))
        )

        const result: Player[] = []

        querySnapshot.docs.forEach(async (doc) => {
            const data = doc.data()

            result.push(new Player({
                id: doc.id,
                name: data.name,
                surname: data.surname,
                status: data.status,
            }))
        })

        return result.sort((a: Player, b: Player) => a.status - b.status)
    }
    async Get(id: string): Promise<Player> {
        const docRef = doc(this.firestore, "players", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error("Player not found");
        }

        const data = docSnap.data()
        return new Player({
            id: docSnap.id,
            name: data.name,
            surname: data.surname,
            status: data.status,
        })
    }
    async Create(data: Player): Promise<Player> {
        const result = await httpsCallable<Player, Player>(this.functions, 'playerSave')(data)

        const id = result.data.id

        return new Player({
            id,
            name: data.name,
            surname: data.surname,
            status: data.status,
        })
    }
    async Update(id: string, data: Player): Promise<Player> {
        data.id = id

        await httpsCallable<Player, Player>(this.functions, 'playerSave')(data)

        return new Player({
            id,
            name: data.name,
            surname: data.surname,
            status: data.status,
        })
    }

    async onUpdated(id: string, data: Player) {
        await this.dataRepo.UpdatePlayerName(id, data.name, data.surname)
        if (!data.isPending()) {
            await this.notiRepo.SetActionNewPlayer(id)
        }
    }

    async Delete(data: Player): Promise<void> {
        await httpsCallable(this.functions, 'playerDelete')({ id: data.id })

        this.onDeleted(data)
    }

    async onDeleted(data: Player) {
        await this.dataRepo.DeletePlayerInEvent(data.id)
    }

    async BulkInsert(data: Player[]): Promise<Player[]> {
        await httpsCallable(this.functions, 'insertPlayers')(data)

        return data
    }
}