import { useCallback, useEffect, useState } from "react";
import PlayerService from "../domain/players/service";
import { firestore, functions } from "../firebase";
import PlayerRepo from "../repository/players";
import { Player } from "../domain/players/model";
import { message } from "antd";

const playerService = new PlayerService(new PlayerRepo(firestore, functions))

export function useListPlayer(svc = playerService) {
    const [list, setList] = useState<Player[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Refresh = useCallback(async (input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined }) => {
        setLoading(true)
        svc.List().then((events) => {
            setList(events)
        }).catch((err) => {
            setError(err)
            message.error(err.message)
            console.error(err);
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        if (loading) {
            Refresh()
        }
    }, [Refresh, loading])

    return { list, loading, error, Refresh }
}

export function useDeletePlayer(svc = playerService) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Delete = useCallback(async (data: Player) => {
        if (data.id) {
            setLoading(true)
            return svc.Delete(data).then(() => {
                message.success("Player deleted")
            }).catch((err) => {
                message.error(err.message)
                setError(err)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [svc])

    return { loading, error, Delete }
}

export function useFormPlayer(id: string, svc = playerService) {
    const [data, setData] = useState(new Player())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Save = useCallback((newData: Player) => {
        return svc.Save(newData).then(() => {
            message.success("Player saved")
        }).catch((err) => {
            message.error(err.message)
        })
    }, [svc])

    const Delete = useCallback(async () => {
        if (data.id) {
            return svc.Delete(data).then(() => {
                message.success("Player deleted")
            }).catch((err) => {
                message.error(err.message)
            })
        }
    }, [svc, data])

    useEffect(() => {
        if (id !== '') {
            svc.Get(id).then((v: Player) => {
                setData(v)
            }).catch((err) => {
                setError(err)
                message.error(err)
                console.error(err);
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [id, svc])

    return {
        data, loading, error, Save, Delete
    }
}

export const useSavePlayer = (svc = playerService) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Save = useCallback((newData: Player) => {
        setLoading(true)
        return svc.Save(newData).then(() => {
            message.success("Player saved")
        }).catch((err) => {
            message.error(err.message)
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return { loading, error, Save }
}