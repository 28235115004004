import { collection, deleteDoc, doc, Firestore, getDocs, query, setDoc, where } from "firebase/firestore"
import { Data } from "../domain/datas/model"
import { CheckIn } from "../domain/checkins/model"
import dayjs from "dayjs";

export class DataRepo {
    firestore: Firestore

    constructor(firestore: Firestore) {
        this.firestore = firestore
    }

    async ListEventPlayer(eventId: string) {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "events", eventId, "players"))
        )

        return querySnapshot.docs.map(async (doc) => {
            return doc.data() as Data
        })
    }

    async SaveEventPlayerCheckIn(eventId: string, player: { id: string, name: string, surname: string }, checkIn: CheckIn) {
        await setDoc(doc(this.firestore, "events", eventId, "players", player.id), {
            group: `${checkIn.group}`,
            firstname: player.name,
            lastname: player.surname,
            caddieNo: checkIn.caddie,
            hole: `${checkIn.hole}`,
            playDate: dayjs(checkIn.playDate).format('YYYY-MM-DD'),
            time: dayjs(checkIn.playDate).format('HH:mm')
        })
    }

    async DeleteEventPlayerCheckIn(eventId: string, playerId: string) {
        await deleteDoc(doc(this.firestore, "events", eventId, "players", playerId))
    }

    async UpdatePlayerName(playerId: string, name: string, surname: string) {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "checkins"), where("playerId", "==", playerId))
        )

        const checkins = querySnapshot.docs.map(async (d) => {
            const checkin = d.data()

            await setDoc(doc(this.firestore, "events", checkin.eventId, "players", playerId), {
                firstname: name,
                lastname: surname
            }, {
                merge: true,
            })

            return checkin
        })

        await Promise.all(checkins)

    }

    async DeletePlayerInEvent(playerId: string) {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "checkins"), where("playerId", "==", playerId))
        )

        const checkins = querySnapshot.docs.map(async (d) => {
            const checkin = d.data()

            await deleteDoc(doc(this.firestore, "events", checkin.eventId, "players", playerId))

            return checkin
        })

        await Promise.all(checkins)
    }
}