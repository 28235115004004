import React from 'react';
import LoginScreen from './containers/LoginScreen';
import SystemLayout from './containers/SystemLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './containers/HomeScreen';
import EventScreen from './containers/EventScreen';
import EventFormScreen from './containers/EventFormScreen';
import PlayerScreen from './containers/PlayerScreen';
import PlayerFormScreen from './containers/PlayerFormScreen';
import CheckInScreen from './containers/CheckInScreen';
import CheckInFormScreen from './containers/CheckInFormScreen';
import NotificationScreen from './containers/NotificationScreen';
import DataScreen from './containers/DataScreen';
import { useAuth } from './hooks/userCustomHook';
import LoadingScreen from './containers/LoadingScreen';

const App: React.FC = () => {
  const { isLogin, loading } = useAuth()

  if (loading) {
    return (<LoadingScreen />)
  }

  if (!isLogin) {
    return (<LoginScreen />)
  }

  return (
    <BrowserRouter>
      <SystemLayout>
        <Routes>
          <Route index element={<HomeScreen />} />
          <Route path='/events/events' element={<EventScreen />} />
          <Route path='/events/events/:id' element={<EventFormScreen />} />
          <Route path='/events/players' element={<PlayerScreen />} />
          <Route path='/events/players/:id' element={<PlayerFormScreen />} />
          <Route path='/events/checkins' element={<CheckInScreen />} />
          <Route path='/events/checkins/:id' element={<CheckInFormScreen />} />
          <Route path='/events/data' element={<DataScreen />} />
          <Route path='/notifications' element={<NotificationScreen />} />
          <Route path="*" element={<HomeScreen />} />
        </Routes>
      </SystemLayout>
    </BrowserRouter>
  )
};

export default App;
