import { Button, Flex, Form, Image, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useListEvent, useSyncEventData } from '../hooks/eventCustomHook'
import { CheckCircleFilled, CloseCircleFilled, SyncOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Event } from '../domain/events/model'
import Search from 'antd/es/input/Search'
import dayjs from 'dayjs'

const SpinButton = ({ onClick }: { onClick: () => Promise<void> }) => {
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setLoading(true)
    onClick().finally(() => {
      setLoading(false)
    })
  }

  return (
    <Button type='link' onClick={handleClick}><SyncOutlined spin={loading} /></Button>
  )
}

export default function EventScreen() {
  const { list, loading } = useListEvent()
  const [data, setData] = useState(list)
  const { SyncData } = useSyncEventData()

  const columns = [
    {
      title: 'Logo',
      dataIndex: 'logo',
      key: 'logo',
      render: (_: any, data: Event) => <Image style={{ maxHeight: '4rem', maxWidth: '12rem' }} src={data.getLogoUrl()} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, obj: Event) => <Link to={`/events/events/${obj.id}`}>{text}</Link>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: Date) => text ? dayjs(text).format('YYYY-MM-DD') : '',
      sorter: {
        compare: (a: Event, b: Event) => a.startDate.getTime() - b.startDate.getTime(),
        // multiple: 7,
      },
    },
    {
      title: 'Event Type',
      dataIndex: 'type',
      key: 'type',
      render: (t: number) => ['', '2 Groups', '3 Groups', '3 Groups(5 Members)'].at(t),
    },
    {
      title: 'Enable',
      dataIndex: 'enable',
      key: 'enable',
      render: (e: boolean) => (e ? <CheckCircleFilled style={{ color: 'green', fontSize: '1.5rem' }} /> : <CloseCircleFilled style={{ color: 'red', fontSize: '1.5rem' }} />),
      sorter: {
        compare: (a: Event, b: Event) => a.enable ? -1 : 1,
        // multiple: 8,
      },
    },
    {
      title: 'Sync Data',
      render: (_: any, e: Event) => (<SpinButton onClick={async () => SyncData(e.id)} />),
    },
  ]

  const onSearch = (search: string) => {
    if (search.length) {
      setData(list.filter((e: Event) => e.name.toLowerCase().includes(search.toLowerCase())))
    } else {
      setData(list)
    }
  }

  useEffect(() => {
    if (!loading) {
      setData(list)
    }
  }, [loading, list])

  return (
    <>
      <Flex gap={10} justify={'right'}>
        <Form.Item>
          <Search placeholder="input search text" onSearch={onSearch} style={{ minWidth: 300 }} />
        </Form.Item>
        <Link to='/events/events/create'>
          <Button type='primary'>Add New</Button>
        </Link>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        pagination={{ defaultPageSize: 10, }}
        showHeader={true}
      ></Table >
    </>
  )
}