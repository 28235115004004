import { useCallback, useEffect, useMemo, useState } from 'react'
import { User } from '../domain/users/model'
import { auth, db } from '../firebase'
import { onValue, ref } from 'firebase/database'
import UserService from '../domain/users/service'
import UserRepo from '../repository/users'
import { message } from 'antd'

export function useUser(svc = new UserService(new UserRepo(db, auth))) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [isLogin, setIsLogin] = useState(false)
    const [user, setUser] = useState<User>()

    const Refresh = useMemo(() => {
        setLoading(true)
        svc.Get().then((user) => {
            setUser(user)
            setIsLogin(true)
            setLoading(false)
        }).catch((err) => {
            setError(err)
            setIsLogin(false)
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        if (!isLogin && auth.currentUser?.uid) {
            const userId = auth.currentUser?.uid
            const userRef = ref(db, `admin/users/${userId}`);
            return onValue(userRef, (snapshot) => {
                const data = snapshot.val();
                setUser(new User({
                    id: userId,
                    name: auth.currentUser?.displayName || '',
                    enable: data.enable,
                }))
                setLoading(false)
            })
        }
    }, [isLogin])

    return { loading, user, error, Refresh }
}

export function useLogin(svc = new UserService(new UserRepo(db, auth))) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(new User())
    const [error, setError] = useState(null)
    const [isLogin, setIsLogin] = useState(false)

    const Login = useCallback((email: string, password: string) => {
        setLoading(true)
        svc.Login({ email, password }).then((user) => {
            setData(user)
            setIsLogin(true)
        }).catch((err) => {
            message.error(err.message)
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        auth.authStateReady().then(() => {
            setLoading(false)
        },)
    }, [])

    return {
        loading, data, error, isLogin,
        Login
    }
}

export function useLogout(svc = new UserService(new UserRepo(db, auth))) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Logout = useCallback(() => {
        setLoading(true)
        svc.Logout().then(() => {
            setLoading(false)
        }).catch((err) => {
            setError(err)
        })
    }, [svc])

    return {
        loading, error, Logout
    }
}

export function useAuth() {
    const [data, setData] = useState(new User())
    const [isLogin, setIsLogin] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        auth.authStateReady().then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        return auth.onAuthStateChanged((u) => {
            if (u) {
                setData(new User({
                    id: u.uid,
                    name: u.displayName || '',
                    enable: true,
                }))
                setIsLogin(true)
            } else {
                setData(new User())
                setIsLogin(false)
            }
        })
    }, [])

    return { data, isLogin, loading }
}