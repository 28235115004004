import { CheckIn } from "../checkins/model";
import { Event } from "../events/model";
import { Player } from "../players/model";

export class NotificationPlayer {
    id: string;
    fullname: string;
    constructor(data: {
        id: string,
        fullname: string,
    }) {
        this.id = data?.id ? data.id : ''
        this.fullname = data?.fullname ? data.fullname : ''
    }
}

export class NotificationEvent {
    id: string;
    name: string;
    constructor(data: {
        id: string,
        name: string,
    }) {
        this.id = data?.id ? data.id : ''
        this.name = data?.name ? data.name : ''
    }
}

export class NotificationCheckIn {
    id: string;
    constructor(data: {
        id: string,
    }) {
        this.id = data?.id ? data.id : ''
    }
}

export class Notification {
    id: string;
    title: string;
    type: number;
    detail: {
        player: NotificationPlayer | undefined,
        event: NotificationEvent | undefined,
        checkin: NotificationCheckIn | undefined,
    } | undefined;
    status: number;
    createdAt: Date;
    updatedAt: Date | undefined;

    constructor(data: {
        id: string,
        title: string,
        type: number,
        detail: {
            player: Player | undefined,
            event: Event | undefined,
            checkin: CheckIn | undefined,
        } | undefined,
        status: number,
        createdAt: Date,
        updatedAt: Date | undefined,
    }) {
        this.id = data?.id ? data.id : ''
        this.title = data?.title ? data.title : ''
        this.type = data?.type ? data.type : 1
        this.detail = {
            player: undefined,
            event: undefined,
            checkin: undefined,
        }

        if (data?.detail) {
            this.detail = {
                player: data.detail.player ? new NotificationPlayer({
                    id: data.detail.player.id,
                    fullname: data.detail.player.name + ' ' + data.detail.player.surname,
                }) : undefined,
                event: data.detail.event ? new NotificationEvent({
                    id: data.detail.event.id,
                    name: data.detail.event.name,
                }) : undefined,
                checkin: data.detail.checkin ? new NotificationCheckIn({
                    id: data.detail.checkin.id,
                }) : undefined,
            }
        }
        this.status = data?.status ? data.status : 0
        this.createdAt = data?.createdAt ? data.createdAt : new Date()

        if (data?.updatedAt) {
            this.updatedAt = data.updatedAt
        }
    }

    hasAction(): boolean {
        return this.status === 2
    }

    setAction() {
        this.status = 2
    }

    setPlayer(player: Player) {
        if (this.detail) {
            this.detail.player = new NotificationPlayer({
                id: player.id,
                fullname: player.name + ' ' + player.surname,
            })
        }
    }

    setEvent(event: Event) {
        if (this.detail) {
            this.detail.event = new NotificationEvent({
                id: event.id,
                name: event.name,
            })
        }
    }

    setCheckIn(checkin: CheckIn) {
        if (this.detail) {
            this.detail.checkin = new NotificationCheckIn({
                id: checkin.id,
            })
        }
    }

    isTypeCheckIn(): boolean {
        return this.type === 1
    }

    isTypePlayer(): boolean {
        return this.type === 2
    }
}