export class User {
    id: string;
    name: string;
    enable: boolean;
    constructor(data?: {
        id: string,
        name: string,
        enable: boolean,
    }) {
        this.id = data?.id ? data.id : ''
        this.name = data?.name ? data.name : ''
        this.enable = data?.enable ? data.enable : false
    }

    isEnabled(): boolean {
        return this.enable
    }
}