import { Button, Flex, Form, GetProp, Select, Upload, UploadProps } from 'antd'
import React, { useState } from 'react'
import { useGetEvent, useImportPlayer, useListEvent } from '../hooks/eventCustomHook'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import readXlsxFile from 'read-excel-file'
import dayjs from 'dayjs'
import { EventPlayer } from '../domain/events/model'
import writeXlsxFile from 'write-excel-file'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

enum Golfer {
  Professional = 'Professional',
  Amateur = 'Amateur'
}

const header = [
  {
    value: 'Group',
  },
  {
    value: 'Firstname',
  },
  {
    value: 'Lastname',
  },
  {
    value: 'Caddie No',
  },
  {
    value: 'Hole',
  },
  {
    value: 'Play Date',
  },
  {
    value: 'Time',
  },
  {
    value: 'Golfer'
  },
  {
    value: 'Order'
  }
]

const eventTemplate = [
  {
    type: 1,
    group: 2,
    member: 4,
  }, {
    type: 2,
    group: 3,
    member: 4,
  }, {
    type: 3,
    group: 3,
    member: 5,
  }
]

function DataScreen() {
  const { Get, } = useGetEvent()
  const { list } = useListEvent()
  const { loading, Import } = useImportPlayer()
  const [form, setForm] = useState({
    eventId: '',
  })

  const handleDownload = () => {
    Get(form.eventId).then((result) => {
      const template = eventTemplate.find((e) => e.type === result?.type)
      const body: { value: string }[][] = [header]

      new Array(18).fill(0).forEach((_, indexHole) => {
        new Array(template?.group).fill(0).forEach((_, indexGroup) => {
          new Array(template?.member).fill(0).forEach((_, index) => {
            body.push([
              {
                value: `${indexGroup + 1}`
              }, {
                value: '',
              }, {
                value: '',
              }, {
                value: '',
              }, {
                value: `${indexHole + 1}`,
              }, {
                value: result?.startDate ? dayjs(result.startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
              }, {
                value: '',
              }, {
                value: index === 0 ? Golfer.Professional : Golfer.Amateur,
              }, {
                value: `${index + 1}`,
              }
            ])
          })
        })
      })

      writeXlsxFile(body, {
        fileName: 'Player List',
      })
    })
  }

  const handleBeforeUpload = (file: FileType) => {
    readXlsxFile(file, {
      schema: {
        'Group': {
          prop: 'group',
          type: Number,
        },
        'Firstname': {
          prop: 'firstname',
          type: String,
        },
        'Lastname': {
          prop: 'lastname',
          type: String,
        },
        'Caddie No': {
          prop: 'caddie',
          type: String,
        },
        'Hole': {
          prop: 'hole',
          type: Number,
        },
        'Play Date': {
          prop: 'playDate',
          type: Date,
        },
        'Time': {
          prop: 'time',
          type: String,
        },
        'Golfer': {
          prop: 'golfer',
          type: String,
        },
        'Order': {
          prop: 'order',
          type: Number,
        }
      }
    }).then(({ rows }: { rows: any[] }) => {
      const result: EventPlayer[] = []

      rows.forEach((row: any, i: number) => {
        if (`${row.firstname} ${row.lastname}`.trim().length === 0) {
          return
        }

        if (row.firstname === '.') {
          return
        }

        result.push({
          id: '',
          group: row.group,
          firstname: row.firstname,
          lastname: row.lastname,
          caddieNo: row.caddieNo,
          hole: row.hole,
          playDate: dayjs(row.playDate).format('YYYY-MM-DD'),
          time: row.time,
          checked: false,
          order: row.order - 1,
          isCaptain: row.golfer === Golfer.Professional
        })
      })

      Import(form.eventId, result)
    })

    return false
  }

  return (
    <Form layout='vertical' style={{ maxWidth: '50%' }}>
      <Flex>
        <Form.Item label='Choose Event' layout='horizontal' style={{ width: '100%' }}>
          <Select value={form.eventId} onChange={(v: string) => setForm({ ...form, eventId: v })}>
            {list.map((e: any) => <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Flex>
      <Flex gap={40} justify='center'>
        <Flex>
          <Button type='primary' disabled={!form.eventId} onClick={handleDownload}><DownloadOutlined />Download Template</Button>
        </Flex>
        <Flex>
          <Upload
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
          >
            <Button type='default' loading={loading} disabled={!form.eventId}><UploadOutlined /> Select file for import</Button>
          </Upload>
        </Flex>
      </Flex>
    </Form>
  )
}

export default DataScreen