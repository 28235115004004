import dayjs from "dayjs";

export class EventTheme {
    logo: string
    background: string
    mainColor: string
    holeColor: string
    parColor: string
    groupColor: string
    pendingColor: string
    checkInColor: string
    checkedColor: string
    holeBackground: string
    groupBackground: string
    borderColor: string

    constructor(data?: {
        logo: string,
        background: string,
        mainColor: string,
        holeColor: string,
        parColor: string,
        groupColor: string,
        pendingColor: string,
        checkInColor: string,
        checkedColor: string,
        holeBackground: string,
        groupBackground: string,
        borderColor: string,
    }) {
        this.logo = data?.logo || ''
        this.background = data?.background || ''
        this.mainColor = data?.mainColor || '#FFFFFF'
        this.holeColor = data?.holeColor || '#000000'
        this.parColor = data?.parColor || '#000000'
        this.groupColor = data?.groupColor || '#000000'
        this.pendingColor = data?.pendingColor || '#000000'
        this.checkInColor = data?.checkInColor || '#000000'
        this.checkedColor = data?.checkedColor || '#000000'
        this.holeBackground = data?.holeBackground || '#FFFFFF'
        this.groupBackground = data?.groupBackground || '#FFFFFF'
        this.borderColor = data?.borderColor || '#000000'
    }

    getLogoUrl() {
        return this.logo ? `https://storage.googleapis.com/lbg-system.appspot.com/events/${this.logo}` : ''
    }

    getBackgroundUrl(): string | undefined {
        return this.background ? `https://storage.googleapis.com/lbg-system.appspot.com/events/${this.background}` : undefined
    }
}

export class Event {
    id: string;
    name: string;
    startDate: Date;
    type: number;
    enable: boolean;
    hole: {
        hole: number
        par: number
    }[];
    theme: EventTheme;

    constructor(data?: {
        id: string,
        name: string,
        startDate: Date,
        type: number,
        enable: boolean,
        hole: {
            hole: number
            par: number
        }[],
        theme: EventTheme,
    }) {
        this.id = data?.id ? data.id : ''
        this.name = data?.name ? data.name : ''
        this.startDate = data?.startDate ? data.startDate : new Date()
        this.type = data?.type ? data.type : 1
        this.enable = data?.enable ? data.enable : false
        this.hole = data?.hole ? data.hole : new Array(18).fill({ hole: 0, par: 0 }).map((_, index) => ({ hole: index + 1, par: 3 }))
        this.theme = data?.theme ? data.theme : new EventTheme()
    }

    getLogoUrl(): string {
        return this.theme.getLogoUrl()
    }

    getBackgroundUrl(): string | undefined {
        return this.theme.getBackgroundUrl()
    }

    setDisable() {
        this.enable = false
    }

    setEnable() {
        this.enable = true
    }

    setStartDate(date: Date) {
        this.startDate = date
    }

    isEnable(): boolean {
        return this.enable
    }

    isDisabled(): boolean {
        return !this.enable
    }

    getTotalGroup(): number {
        return this.type === 1 ? 2 : 3
    }

    getTotalHole(): number {
        return 18
    }

    getTotalMember(): number {
        return this.type === 3 ? 5 : 4
    }
}

export class EventPlayer {
    id: string
    group: number
    firstname: string
    lastname: string
    caddieNo: string
    hole: number
    playDate: string
    time: string
    checked: boolean
    order: number
    isCaptain: boolean
    constructor(data?: {
        id: string,
        group: number,
        firstname: string,
        lastname: string,
        caddieNo: string,
        hole: number,
        playDate: string,
        time: string,
        checked: boolean,
        order: number,
        isCaptain: boolean
    }) {
        this.id = data?.id ? data.id : ''
        this.group = data?.group ? data.group : 1
        this.firstname = data?.firstname ? data.firstname : ''
        this.lastname = data?.lastname ? data.lastname : ''
        this.caddieNo = data?.caddieNo ? data.caddieNo : ''
        this.hole = data?.hole ? data.hole : 1
        this.playDate = data?.playDate ? data.playDate : dayjs().format('YYYY-MM-DD')
        this.time = data?.time ? data.time : dayjs().minute(0).second(0).format('HH:mm')
        this.checked = data?.checked ? data.checked : false
        this.order = data?.order ? data.order : 0
        this.isCaptain = data?.isCaptain ? data.isCaptain : false
    }
} 