import { collection, doc, Firestore, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { CheckIn, CheckInEvent, CheckInPlayer } from "../domain/checkins/model";
import { CheckInRepository } from "../domain/checkins/repository";
import { DataRepo } from "./data";
import NotificationRepo from "./notifications";
import { Functions, httpsCallable } from "firebase/functions";

export default class CheckinRepo implements CheckInRepository {
    firestore: Firestore
    dataRepo: DataRepo
    notiRepo: NotificationRepo
    functions: Functions
    constructor(firestore: Firestore, functions: Functions, dataRepo = new DataRepo(firestore), notiRepo = new NotificationRepo(firestore)) {
        this.firestore = firestore
        this.dataRepo = dataRepo
        this.notiRepo = notiRepo
        this.functions = functions
    }
    async List(input: { search: string; page: number; totalPerPage: number; event: string; }): Promise<CheckIn[]> {
        const snapshot = await getDocs(
            query(collection(this.firestore, "checkins"),
                orderBy("playDate", "desc"),
            ))

        const listData = snapshot.docs.map(d => {
            const data = d.data()

            const checkin = new CheckIn({
                id: d.id,
                caddie: data.caddie,
                createdAt: data.createdAt,
                group: data.group,
                hole: data.hole,
                playDate: data.playDate.toDate(),
                status: data.status,
                checked: data.checked,
                isCaptain: data.isCaptain,
                order: data.order,
            })

            checkin.player = new CheckInPlayer({
                id: data.player.id,
                name: data.player.firstname,
                surname: data.player.lastname,
            })

            checkin.event = new CheckInEvent({
                id: data.event.id,
                name: data.event.name
            })

            return checkin
        })

        return listData
    }
    async GetById(id: string): Promise<CheckIn> {
        const docRef = doc(this.firestore, "checkins", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error("Event not found");
        }

        const data = docSnap.data()

        const checkin = new CheckIn({
            id: docSnap.id,
            caddie: data.caddie,
            createdAt: data.createdAt,
            group: data.group,
            hole: data.hole,
            playDate: data.playDate.toDate(),
            status: data.status,
            checked: data.checked,
            isCaptain: data.isCaptain,
            order: data.order,
        })

        checkin.player = new CheckInPlayer({
            id: data.player.id,
            name: data.player.firstname,
            surname: data.player.lastname,
        })

        checkin.event = new CheckInEvent({
            id: data.event.id,
            name: data.event.name
        })

        return checkin
    }
    async Create(data: CheckIn): Promise<CheckIn> {
        const result = await httpsCallable<any, CheckIn>(this.functions, "checkInSave")({
            player: {
                id: data.player.id,
                name: data.player.name,
                surname: data.player.surname
            },
            event: {
                id: data.event.id,
                name: data.event.name
            },
            playDate: data.playDate,
            group: data.group,
            hole: data.hole,
            caddie: data.caddie,
            status: data.status,
            createdAt: new Date(),
            checked: false,
            order: data.order,
            isCaptain: data.isCaptain,
        })

        data.id = result.data.id

        return data
    }

    async Update(id: string, data: CheckIn): Promise<CheckIn> {
        await httpsCallable<any, CheckIn>(this.functions, "checkInSave")({
            id,
            player: {
                id: data.player.id,
                name: data.player.name,
                surname: data.player.surname
            },
            event: {
                id: data.event.id,
                name: data.event.name
            },
            playDate: data.playDate,
            group: data.group,
            hole: data.hole,
            caddie: data.caddie,
            status: data.status,
            checked: data.checked,
            order: data.order,
            isCaptain: data.isCaptain,
        })

        return data
    }

    async Delete(data: CheckIn): Promise<void> {
        await httpsCallable(this.functions, "checkInDelete")({ id: data.id })

        this.onDeleted(data)

        return
    }

    async onDeleted(data: CheckIn) {
    }

    async BulkInsert(data: CheckIn[]): Promise<CheckIn[]> {
        return Promise.all(data.map((d) => this.Create(d)))
    }
}