import React, { useState } from 'react';
import { BellOutlined, CalendarOutlined, HomeOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Badge, Button, Layout, Menu, theme } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettings } from '../hooks/settingCustomHook';
import { useLogout } from '../hooks/userCustomHook';

const { Header, Content, Sider } = Layout;

export default function SystemLayout({ children }: React.PropsWithChildren) {
  const [collapsed, setCollapsed] = useState(false);
  const { Logout } = useLogout()
  const navigate = useNavigate()
  const location = useLocation()
  const settings = useSettings()

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: "100vh", height: '100%', overflow: "auto" }}>
      <Sider trigger={null} collapsible collapsed={collapsed} width={'20em'}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          defaultOpenKeys={location.pathname.split('/').length > 2 ? [`/${location.pathname.split('/')[1]}`] : []}
          items={[
            {
              key: '/',
              icon: <HomeOutlined />,
              label: 'Home',
              onClick: () => {
                navigate('/')
              }
            },
            {
              key: '/events',
              icon: <CalendarOutlined />,
              label: 'Events',
              children: [
                {
                  key: '/events/events',
                  label: 'Events',
                  onClick: () => {
                    navigate('/events/events')
                  }
                },
                {
                  key: '/events/players',
                  label: 'Players',
                  onClick: () => {
                    navigate('/events/players')
                  }
                },
                {
                  key: '/events/checkins',
                  label: 'Check In',
                  onClick: () => {
                    navigate('/events/checkins')
                  }
                },
                {
                  key: '/events/data',
                  label: 'Export & Import Player',
                  onClick: () => {
                    navigate('/events/data')
                  }
                },
              ]
            },
            {
              key: '/notifications',
              icon:
                collapsed ? settings.list.length > 0 && (
                  <Badge dot>
                    <BellOutlined style={{ lineHeight: '0.7rem' }} />
                  </Badge>
                ) : (
                  <Badge count={settings.list.length} size='small'>
                    <BellOutlined />
                  </Badge>
                ),
              label: 'Notifications',
              onClick: () => {
                navigate('/notifications')
              },
            },
            {
              key: 'signout',
              icon: <LogoutOutlined />,
              label: 'Sign Out',
              onClick: () => {
                Logout()
                navigate('/')
              }
            }
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout >
  );
}