import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { initializeFirestore, memoryLocalCache } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

import firebaseConfig from '../firebaseConfig.json'

export const app = initializeApp(firebaseConfig)

export const db = getDatabase(app)

export const firestore = initializeFirestore(app, {
    localCache: memoryLocalCache(),
})

export const functions = getFunctions(app, 'asia-southeast2')

if (process.env.NODE_ENV === 'test') {
    connectFunctionsEmulator(functions, 'localhost', 5001)
}


export const auth = getAuth(app)

export const storage = getStorage(app)