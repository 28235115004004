import { CheckIn } from "./model";
import { CheckInRepository } from "./repository";

export class CheckInService {
    repo: CheckInRepository;
    constructor(repo: CheckInRepository) {
        this.repo = repo
    }

    async List(input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined, event: string | undefined }): Promise<CheckIn[]> {
        const event = input?.event ? input.event : ''
        const search = input?.search ? input.search : ''
        const page = input?.page ? input.page : 1
        const totalPerPage = input?.totalPerPage ? input.totalPerPage : 10

        const result = await this.repo.List({
            event,
            search,
            page,
            totalPerPage,
        })

        return result
    }

    async Get(id: string): Promise<CheckIn> {
        return await this.repo.GetById(id)
    }

    async Save(data: CheckIn): Promise<CheckIn> {
        if (data.id) {
            return await this.repo.Update(data.id, data)
        }
        
        return await this.repo.Create(data)
    }

    async Delete(data: CheckIn): Promise<void> {
        return await this.repo.Delete(data)
    }
}