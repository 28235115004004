import { Player } from "./model";
import PlayerRepository from "./repository";

export default class PlayerService {
    repo: PlayerRepository
    constructor(repo: PlayerRepository) {
        this.repo = repo
    }

    List(input?: { search: string, page: number, totalPerPage: number, status: string }): Promise<Player[]> {
        const page = input?.page ? input.page : 1
        const totalPerPage = input?.totalPerPage ? input.totalPerPage : 10

        return this.repo.List({
            search: input?.search ? input.search : '',
            page,
            totalPerPage,
            status: input?.status ? input.status : '',
        })
    }

    Get(id: string): Promise<Player> {
        return this.repo.Get(id)
    }

    Save(data: Player): Promise<Player> {
        if (data.id) {
            return this.repo.Update(data.id, data)
        }
        return this.repo.Create(data)
    }

    Delete(data: Player): Promise<void> {
        return this.repo.Delete(data)
    }

    async BulkImport(data: Player[]): Promise<Player[]> {
        const result = await Promise.all(data.map(async (item): Promise<Player> => {
            return this.repo.Create(item)
        }))

        return result
    }
}