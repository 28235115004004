export class Player {
    id: string;
    name: string;
    surname: string;
    status: number;

    constructor(data?: {
        id: string,
        name: string,
        surname: string,
        status: number,
    }) {
        this.id = data?.id ? data.id : ''
        this.name = data?.name ? data.name : ''
        this.surname = data?.surname ? data.surname : ''
        this.status = data?.status ? data.status : 1
    }

    getFullname(): string { 
        return (this?.name || '') + ' ' + (this?.surname || '')
    }

    isPending(): boolean {
        return this.status === 1
    }

    isApproved(): boolean {
        return this.status === 2
    }

    isRejected(): boolean {
        return this.status === 3
    }
}