import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { Firestore } from "firebase/firestore";
import { Event, EventPlayer, EventTheme } from "../domain/events/model";
import EventRepository from "../domain/events/repository";
import { Auth } from "firebase/auth";
import { FirebaseStorage, ref, uploadBytes } from "firebase/storage";
import PlayerRepo from "./players";
import CheckinRepo from "./checkins";
import { Functions, httpsCallable } from "firebase/functions";

export default class EventRepo implements EventRepository {
    firestore: Firestore
    storage: FirebaseStorage
    playerRepo: PlayerRepo
    checkInRepo: CheckinRepo
    functions: Functions
    constructor(firestore: Firestore, storage: FirebaseStorage, auth: Auth, functions: Functions, playerRepo = new PlayerRepo(firestore, functions), checkInRepo = new CheckinRepo(firestore, functions)) {
        this.firestore = firestore
        this.storage = storage
        this.playerRepo = playerRepo
        this.checkInRepo = checkInRepo
        this.functions = functions
    }

    async List(input?: { search: string; page: number; totalPerPage: number; }): Promise<Event[]> {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "events"))
        )

        const result: Event[] = []

        querySnapshot.docs.forEach(async (doc) => {
            const data = doc.data()

            const theme = new EventTheme({
                logo: data?.theme?.logo || data?.logo,
                background: data?.theme?.background || data?.background,
                mainColor: data?.theme?.mainColor || data?.color,
                checkedColor: data?.theme?.checkedColor,
                checkInColor: data?.theme?.checkInColor,
                groupColor: data?.theme?.groupColor,
                holeColor: data?.theme?.holeColor,
                parColor: data?.theme?.parColor,
                pendingColor: data?.theme?.pendingColor,
                groupBackground: data?.theme?.groupBackground,
                holeBackground: data?.theme?.holeBackground,
                borderColor: data?.theme?.borderColor
            })

            result.push(new Event({
                id: doc.id,
                name: data.name,
                startDate: data?.startDate ? data.startDate.toDate() : null,
                type: data.type,
                enable: data.enable,
                hole: data.hole,
                theme: theme,
            }))
        })

        return result.sort((a: Event, b: Event) => (a.enable ? 1 : 0) - (b.enable ? 1 : 0))
    }
    async GetById(id: string): Promise<Event> {
        const docRef = doc(this.firestore, "events", id);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
            throw new Error("Event not found");
        }

        const data = docSnap.data()

        const theme = new EventTheme({
            logo: data?.theme?.logo || data?.logo,
            background: data?.theme?.background || data?.background,
            mainColor: data?.theme?.mainColor || data?.color,
            checkedColor: data?.theme?.checkedColor,
            checkInColor: data?.theme?.checkInColor,
            groupColor: data?.theme?.groupColor,
            holeColor: data?.theme?.holeColor,
            parColor: data?.theme?.parColor,
            pendingColor: data?.theme?.pendingColor,
            groupBackground: data?.theme?.groupBackground,
            holeBackground: data?.theme?.holeBackground,
            borderColor: data?.theme?.borderColor
        })

        const result = new Event({
            id: docSnap.id,
            name: data.name,
            startDate: data?.startDate ? data.startDate.toDate() : null,
            type: data.type,
            enable: data.enable,
            hole: data.hole,
            theme: theme,
        })

        return result
    }

    async ListPlayers(id: string): Promise<EventPlayer[]> {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "events", id, "players"))
        )

        const result: EventPlayer[] = []

        querySnapshot.docs.forEach(async (doc) => {
            const data = doc.data()

            result.push(new EventPlayer({
                id: doc.id,
                group: data.group,
                firstname: data.firstname,
                lastname: data.lastname,
                caddieNo: data.caddieNo,
                hole: data.hole,
                playDate: data.playDate,
                time: data.time,
                checked: data.checked,
                order: data.order,
                isCaptain: data.isCaptain
            }))
        })

        return result.sort((a: EventPlayer, b: EventPlayer) => a.hole - b.hole)
    }

    async Create(data: Event): Promise<Event> {
        const result = await httpsCallable<Event, Event>(this.functions, "eventSave")(data)

        data.id = result.data.id

        return data
    }

    async Update(id: string, data: Event): Promise<Event> {
        data.id = id

        await httpsCallable(this.functions, 'eventSave')(data)

        return data
    }
    async Delete(data: Event): Promise<void> {
        await httpsCallable(this.functions, 'eventDelete')({ id: data.id })

        return
    }

    async ImportPlayers(id: string, data: EventPlayer[]): Promise<EventPlayer[]> {
        await httpsCallable<{ eventId: string, players: EventPlayer[] }, EventPlayer[]>(this.functions, 'eventImportPlayer')({
            eventId: id, players: data
        })

        return data
    }

    async Upload(file: File, pathName: string): Promise<void> {
        await uploadBytes(ref(this.storage, pathName), file)
    }
    async SyncData(eventId: string): Promise<void> {
        await httpsCallable(this.functions, 'eventSyncData')({ eventId })
    }
}