import { useEffect, useState } from 'react'
import { firestore } from '../firebase';
import { Setting } from '../domain/settings/model';
import { doc, onSnapshot } from 'firebase/firestore';

export function useSettings() {
    const [data, setData] = useState(new Setting())

    useEffect(() => {
        const settingRef = doc(firestore, `settings`, 'notifications')

        return onSnapshot(settingRef, (snapshot) => {
            const d = snapshot.data()

            setData(new Setting({
                list: d?.list
            }))
        });
    }, [])

    return { list: data.list }
}