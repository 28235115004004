import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormPlayer } from '../hooks/playerCustomHook'
import { Player } from '../domain/players/model'
import { Button, Flex, Form, Input, message, Radio } from 'antd'
import LoadingScreen from './LoadingScreen'
import { DeleteOutlined } from '@ant-design/icons'

function PlayerFormScreen() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading, Save, Delete } = useFormPlayer(id ? id === 'create' ? '' : id : '')
  const [formLoading, setFormLoading] = useState(false)

  const [form, setForm] = useState({
    name: data.name,
    surname: data.surname,
    status: data.status,
  })

  const handleSubmit = () => {
    setFormLoading(true)
    Save(new Player({
      id: data.id,
      name: form.name,
      surname: form.surname,
      status: form.status,
    })).then(() => {
      navigate('/events/players')
    }).catch((err) => {
      message.error(err)
    }).finally(() => {
      setFormLoading(false)
    })
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this player?')) {
      Delete().then(() => {
        navigate('/events/players')
      }).catch((err) => {
        message.error(err)
      })
    }
  }

  useEffect(() => {
    if (!loading) {
      setForm({
        name: data.name,
        surname: data.surname,
        status: data.status,
      })
    }
  }, [loading, data])

  if (loading) {
    return (<LoadingScreen />)
  }

  return (
    <Form onFinish={handleSubmit} layout='vertical'>
      <Flex justify='space-between'>
        <Flex vertical style={{ minHeight: 200, width: '45%' }}>
          <Flex align='flex-end'>
            <Form.Item label='Name' required style={{ width: '100%' }}>
              <Input type='text' placeholder='Name' value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
            </Form.Item>
          </Flex>
          <Flex align='flex-end'>
            <Form.Item label='Surname' required style={{ width: '100%' }}>
              <Input type='text' placeholder='Surname' value={form.surname} onChange={(e) => setForm({ ...form, surname: e.target.value })} />
            </Form.Item>
          </Flex>
          <Flex align='flex-end'>
            <Form.Item label='Status' style={{ width: '100%' }}>
              <Radio.Group onChange={(e) => setForm({ ...form, status: e.target.value })} value={form.status}>
                <Radio value={1}>Pending</Radio>
                <Radio value={2}>Approve</Radio>
                <Radio value={3}>Reject</Radio>
              </Radio.Group>
            </Form.Item>
          </Flex>
          <Flex align='flex-end'>
            <Form.Item style={{ width: '100%' }}>
              <Button type='primary' htmlType='submit' loading={formLoading}>Save</Button> <Button type='default' onClick={() => window.history.back()}>Back</Button>
            </Form.Item>
          </Flex>
        </Flex>
        {!loading && data.id ? (
          <Flex vertical justify='flex-start'>
            <Button type='default' danger onClick={handleDelete}><DeleteOutlined /></Button>
          </Flex>
        ) : (<></>)
        }

      </Flex>
    </Form>
  )
}

export default PlayerFormScreen