import { arrayRemove, arrayUnion, collection, doc, DocumentData, Firestore, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"
import { Notification } from "../domain/notifications/model";
import NotificationRepository from "../domain/notifications/repository";
import { Player } from "../domain/players/model";
import { Event } from "../domain/events/model";
import { CheckIn } from "../domain/checkins/model";
export default class NotificationRepo implements NotificationRepository {
    firestore: Firestore
    constructor(firestore: Firestore) {
        this.firestore = firestore
    }

    async List(page: number, totalPerPage: number): Promise<Notification[]> {
        const querySnapshot = await getDocs(
            query(collection(this.firestore, "notifications"))
        )

        const notiList = querySnapshot.docs.map(async (d) => {
            const data = d.data()

            const noti = new Notification({
                id: d.id,
                title: data.title,
                type: data.type,
                detail: undefined,
                status: data.status,
                createdAt: data.createdAt,
                updatedAt: data.updatedAt,
            })

            if (data.detail?.playerId) {
                const playerRef = doc(this.firestore, "players", data.detail.playerId);
                const player = await getDoc<DocumentData, DocumentData>(playerRef);
                if (player.exists()) {
                    const playerData = new Player(player.data() as Player)
                    playerData.id = data.detail.playerId

                    noti.setPlayer(playerData)
                }
            }

            if (data.detail?.eventId) {
                const eventRef = doc(this.firestore, "events", data.detail.eventId);
                const event = await getDoc<DocumentData, DocumentData>(eventRef);
                if (event.exists()) {
                    const eventData = new Event(event.data() as Event)
                    eventData.id = data.detail.eventId

                    noti.setEvent(eventData)
                }
            }

            if (data.detail?.checkinId) {
                const checkIn = new CheckIn()
                checkIn.id = data.detail.checkinId
                noti.setCheckIn(checkIn)
            }

            return noti
        })

        const result: Notification[] = await Promise.all(notiList)

        return result.sort((a: Notification, b: Notification) => b.createdAt.getTime() - a.createdAt.getTime())
    }

    async SetActionNewCheckIn(eventId: string, playerId: string) {
        const actionQuery = query(
            collection(this.firestore, "notifications"),
            where("detail.playerId", "==", playerId),
            where("detail.eventId", "==", eventId),
            where("status", "==", 1),
            where("type", "==", 1),
        )

        const querySnapshot = await getDocs(actionQuery)

        const notiList = querySnapshot.docs.map(async (d) => {
            await this.DecreaseTotalNoti(d.id)

            await setDoc(d.ref, {
                status: 2
            }, {
                merge: true
            })
        })

        await Promise.all(notiList)
    }

    async SetActionNewPlayer(playerId: string) {
        const querySnapshot = await getDocs(
            query(
                collection(this.firestore, "notifications"),
                where("detail.playerId", "==", playerId),
                where("status", "==", 1),
                where("type", "==", 2),
            )
        )

        const notiList = querySnapshot.docs.map(async (d) => {
            await this.DecreaseTotalNoti(d.id)

            await setDoc(d.ref, {
                status: 2
            }, {
                merge: true
            })
        })

        await Promise.all(notiList)
    }

    async IncreaseTotalNoti(id: string) {
        await setDoc(doc(this.firestore, "settings", "notifications"), {
            list: arrayUnion(id)
        }, {
            merge: true
        })
    }

    async DecreaseTotalNoti(id: string) {
        await setDoc(doc(this.firestore, "settings", "notifications"), {
            list: arrayRemove(id)
        }, {
            merge: true
        })
    }
}