import { Notification } from "./model";
import NotificationRepository from "./repository";

export default class NotificationService {
    repo: NotificationRepository
    constructor(repo: NotificationRepository) {
        this.repo = repo
    }

    List(input?: { page: number, totalPerPage: number }): Promise<Notification[]> {
        const page = input?.page ? input.page : 1
        const totalPerPage = input?.totalPerPage ? input.totalPerPage : 10

        return this.repo.List(page, totalPerPage)
    }
}