import { useCallback, useEffect, useState } from "react";
import EventService from "../domain/events/service";
import { auth, firestore, functions, storage } from "../firebase";
import EventRepo from "../repository/events";
import { Event, EventPlayer } from "../domain/events/model";
import { message } from "antd";

const eventService = new EventService(new EventRepo(firestore, storage, auth, functions))

export function useListEvent(svc = eventService) {
    const [list, setList] = useState<Event[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Refresh = useCallback((input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined }) => {
        setLoading(true)
        svc.List().then((events) => {
            setList(events)
        }).catch((err) => {
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])



    useEffect(() => {
        if (loading) {
            Refresh()
        }
    }, [Refresh, loading])

    return { list, loading, error, Refresh }
}

export function useSyncEventData(svc = eventService) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const SyncData = useCallback(async (eventId: string) => {
        setLoading(true)
        await svc.SyncData(eventId).then(() => {
            message.success("Sync data success")
        }).catch((err) => {
            setError(err)
            message.error(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return { SyncData, loading, error }
}

export function useFormEvent(id: string, svc = eventService) {
    const [data, setData] = useState(new Event())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const UploadBackground = useCallback((file: File) => {
        return svc.UploadTemp(file).then((result) => {
            message.success("Upload background success")
            return result
        }).catch(err => {
            message.error(err.message)
        })
    }, [svc])

    const UploadLogo = useCallback((file: File) => {
        return svc.UploadTemp(file)
            .then((result) => {
                message.success("Upload logo success")
                return result
            })
            .catch(err => {
                message.error(err.message)
            })
    }, [svc])

    const Save = useCallback((newData: Event) => {
        if (!newData.theme.background) {
            newData.theme.background = data.theme.background
        }

        if (!newData.theme.logo) {
            newData.theme.logo = data.theme.logo
        }

        return svc.Save(newData).then(() => {
            message.success("Event saved")
        })
            .catch(err => {
                message.error(err.message)
                throw err
            })
    }, [svc, data])

    const Delete = useCallback(async () => {
        if (data.id) {
            return svc.Delete(data).then(() => {
                message.success("Event deleted")
            }).catch(err => {
                message.error(err.message)
            })
        }
    }, [svc, data])

    useEffect(() => {
        if (id !== '') {
            svc.Get(id).then((v: Event) => {
                setData(v)
            }).catch((err) => {
                setError(err)
                console.error(err);
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [id, svc])

    return {
        data, loading, error, UploadBackground, UploadLogo, Save, Delete,
    }
}

export function useImportPlayer(svc = eventService) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const Import = useCallback(async (eventId: string, file: EventPlayer[]) => {
        setLoading(true)
        return svc.ImportPlayer(eventId, file).then((result) => {
            message.success("Import player success")
        }).catch((err) => {
            message.error("Import player failed")
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    return {
        loading, error, Import
    }
}

export function useGetEvent() {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState(new Event())

    const Get = useCallback(async (id: string): Promise<void | Event> => {
        setLoading(true)
        return eventService.Get(id).then((result) => {
            setData(result)
            return result
        }).catch((err) => {
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return {
        loading, error, data, Get
    }
}