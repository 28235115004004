import { useCallback, useEffect, useState } from "react"
import { CheckIn } from "../domain/checkins/model"
import { CheckInService } from "../domain/checkins/service"
import { firestore, functions } from "../firebase"
import CheckinRepo from "../repository/checkins"
import { message } from "antd"

const checkInService = new CheckInService(new CheckinRepo(firestore, functions))

export function useListCheckin(svc = checkInService) {
    const [list, setList] = useState<CheckIn[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Refresh = useCallback(async (input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined }) => {
        setLoading(true)
        svc.List().then((events) => {
            setList(events)
        }).catch((err) => {
            console.error(err);
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        if (loading) {
            Refresh()
        }
    }, [Refresh, loading])

    return { list, loading, error, Refresh }
}

export function useDeleteCheckIn(svc = checkInService) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const Delete = useCallback(async (data: CheckIn) => {
        if (data.id) {
            setLoading(true)
            return svc.Delete(data).catch((err) => {
                setError(err)
                message.error(err.message)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [svc])

    return { loading, error, Delete }
}

export function useFormCheckIn(id: string, svc = checkInService) {
    const [data, setData] = useState(new CheckIn())
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Save = useCallback(async(newData: CheckIn) => {
        return svc.Save(newData).then(() => {
            message.success("CheckIn saved")
        }).catch((err) => {
            message.error(err.message)
            throw err
        })
    }, [svc])

    const Delete = useCallback(async () => {
        if (data.id) {
            return svc.Delete(data).then(() => {
                message.success("CheckIn deleted")
            }).catch((err) => {
                message.error(err.message)
            })
        }
    }, [svc, data])

    useEffect(() => {
        if (id !== '') {
            svc.Get(id).then((v: CheckIn) => {
                setData(v)
            }).catch((err) => {
                setError(err)
                message.error(err)
                console.error(err);
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [id, svc])

    return {
        data, loading, error, Save, Delete
    }
}