import { Database, ref, get, child, onValue } from "firebase/database";
import { Auth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import UserRepository from "../domain/users/repository";
import { User } from "../domain/users/model";

export default class UserRepo implements UserRepository {
    db: Database;
    auth: Auth;
    constructor(db: Database, auth: Auth) {
        this.db = db
        this.auth = auth
    }

    async Get(): Promise<User> {
        await this.auth.authStateReady()

        const userId = this.auth?.currentUser?.uid

        if (!userId) {
            throw new Error('Cannot get user')
        }

        const refUser = ref(this.db)
        const snapshot = await get(child(refUser, `admin/users/${userId}`))

        if (!snapshot.exists()) {
            throw new Error('User not found')
        }

        const data = snapshot.val()

        return new User({
            id: userId,
            name: this.auth?.currentUser?.displayName || '',
            enable: data.enable,
        })

    }

    onChange(callBack: (data: User) => void): () => void {
        const userId = this.auth?.currentUser?.uid

        if (!userId) {
            throw new Error('Cannot get user')
        }

        const starCountRef = ref(this.db, `admin/users/${userId}`);
        return onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();

            callBack(new User({
                id: userId,
                name: this.auth?.currentUser?.displayName || '',
                enable: data.enable,
            }))
        });
    }

    async Login(email: string, password: string): Promise<User> {
        await signInWithEmailAndPassword(this.auth, email, password)

        const user = await this.Get()

        return user
    }
    async Logout(): Promise<void> {
        await signOut(this.auth)
    }

    async isLogin(): Promise<boolean> {
        await this.auth.authStateReady()

        return this.auth.currentUser != null
    }
}