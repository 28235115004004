import { Avatar, Button, List, Skeleton, Typography } from 'antd'
import React, { useState } from 'react'
import { useNotificationList } from '../hooks/notificationCustomHook'
import { BellFilled, RightCircleFilled } from '@ant-design/icons'
import { Notification } from '../domain/notifications/model'
import { Link } from 'react-router-dom'

function NotificationScreen() {
  const { list, loading } = useNotificationList()

  const [currentTotal, setCurrentTotal] = useState(4)

  const loadMore =
    !loading && list.length > currentTotal ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={() => {
          setCurrentTotal(currentTotal + 4)
        }}>loading more</Button>
      </div>
    ) : null;


  return (
    <List
      className="demo-loadmore-list"
      loading={loading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={list.slice(0, currentTotal)}
      renderItem={(item: Notification) =>
        item.isTypeCheckIn() ? <NewCheckIn item={item} loading={loading} /> :
          <NewPlayer item={item} loading={loading} />}
    />
  )
}

function NewCheckIn({ loading, item }: React.PropsWithChildren<{ loading: boolean, item: Notification }>) {
  return (
    <List.Item>
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          avatar={<Avatar style={item.hasAction() ? { backgroundColor: '#E8E8E8', border: '4px solid #E8E8E8' } : { backgroundColor: '#FFC42F', border: '4px solid #FFBB55' }}><BellFilled style={{ fontSize: '1rem' }} /></Avatar>}
          title={<Typography.Text strong>{item.title}</Typography.Text>}
          description={<Typography.Text>
            <Typography.Text style={{ color: '#525252' }} strong>{item.detail?.player?.fullname}</Typography.Text>
            <Typography.Text style={{ color: '#525252' }}> Request check in to </Typography.Text>
            <Typography.Text style={{ color: '#525252' }} strong>{item.detail?.event?.name}</Typography.Text>
          </Typography.Text>}
        />
        {!item.hasAction() ? (
          <Link to={`/events/checkins/${item.detail?.checkin?.id}`}>
            <RightCircleFilled style={{ fontSize: '1.5rem', color: '#BCBCBC' }} />
          </Link>
        ) : null}
      </Skeleton>
    </List.Item>
  )
}

function NewPlayer({ loading, item }: React.PropsWithChildren<{ loading: boolean, item: Notification }>) {
  return (
    <List.Item>
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          avatar={<Avatar style={item.hasAction() ? { backgroundColor: '#E8E8E8', border: '4px solid #E8E8E8' } : { backgroundColor: '#FFC42F', border: '4px solid #FFBB55' }}><BellFilled style={{ fontSize: '1rem' }} /></Avatar>}
          title={<Typography.Text strong>{item.title}</Typography.Text>}
          description={<Typography.Text>
            <Typography.Text style={{ color: '#525252' }} strong>{item.detail?.player?.fullname}</Typography.Text>
            <Typography.Text style={{ color: '#525252' }}> Request new player </Typography.Text>
          </Typography.Text>}
        />
        {!item.hasAction() ? (
          <Link to={`/events/players/${item.detail?.player?.id}`}>
            <RightCircleFilled style={{ fontSize: '1.5rem', color: '#BCBCBC' }} />
          </Link>
        ) : null}
      </Skeleton>
    </List.Item>
  )
}

export default NotificationScreen