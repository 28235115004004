import { LoadingOutlined } from '@ant-design/icons'
import { Flex, Spin } from 'antd'
import React from 'react'

function LoadingScreen() {
    return (
        <Flex align="center" gap="middle">
            <Flex style={{ width: '100%', minHeight: '70vh' }} justify={'center'} align={'center'}>
                <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
        </Flex>
    )
}

export default LoadingScreen