import { useCallback, useEffect, useState } from "react"
import NotificationService from "../domain/notifications/service"
import { firestore } from "../firebase"
import NotificationRepo from "../repository/notifications"
import { Notification } from "../domain/notifications/model"

const notificationService = new NotificationService(new NotificationRepo(firestore))
export const useNotificationList = (svc = notificationService) => {
    const [list, setList] = useState<Notification[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    const Refresh = useCallback((input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined }) => {
        setLoading(true)
        svc.List().then((events) => {
            setList(events)
        }).catch((err) => {
            setError(err)
        }).finally(() => {
            setLoading(false)
        })
    }, [svc])

    useEffect(() => {
        if (loading) {
            Refresh()
        }
    }, [Refresh, loading])

    return { list, loading, error, Refresh }
}