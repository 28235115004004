import { User } from "./model";
import UserRepository from "./repository";

export default class UserService {
    repo: UserRepository
    constructor(repo: UserRepository) {
        this.repo = repo
    }

    Get(): Promise<User> {
        return this.repo.Get()
    }

    Login(input: { email: string, password: string }): Promise<User> {
        return this.repo.Login(input.email, input.password)
    }

    Logout(): Promise<void> {
        return this.repo.Logout()
    }

    onChange(callBack: (data: User) => void): () => void {
        return this.repo.onChange(callBack)
    }

    isLogin(): Promise<boolean> {
        return this.repo.isLogin()
    }
}