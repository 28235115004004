import { uuidv7 } from "uuidv7";
import { Event, EventPlayer } from "./model";
import EventRepository from "./repository";

export default class EventService {
    repo: EventRepository;
    constructor(repo: EventRepository) {
        this.repo = repo
    }

    async List(input?: { search: string | undefined, page: number | undefined, totalPerPage: number | undefined }): Promise<Event[]> {
        const search = input?.search ? input.search : ''
        const page = input?.page ? input.page : 1
        const totalPerPage = input?.totalPerPage ? input.totalPerPage : 10

        const result = await this.repo.List({
            search,
            page,
            totalPerPage,
        })

        return result
    }

    async Get(id: string): Promise<Event> {
        return await this.repo.GetById(id)
    }

    async Save(data: Event): Promise<Event> {
        if (data.id) {
            return await this.repo.Update(data.id, data)
        }

        return await this.repo.Create(data)
    }

    async Delete(data: Event): Promise<void> {
        return await this.repo.Delete(data)
    }

    async UploadLogo(file: File, data: Event): Promise<Event> {
        data.theme.logo = `logo/${uuidv7().split('-').join('')}`

        await this.repo.Upload(file, `events/${data.theme.logo}`)

        return data
    }

    async UploadBackground(file: File, data: Event): Promise<Event> {
        data.theme.background = `background/${uuidv7().split('-').join('')}`

        await this.repo.Upload(file, `events/${data.theme.background}`)

        return data
    }

    async UploadTemp(file: File): Promise<Event> {
        const data = new Event()
        const temp = `tmp/${uuidv7().split('-').join('')}`

        data.theme.background = temp
        data.theme.logo = temp

        await this.repo.Upload(file, `events/${temp}`)

        return data
    }

    async ExportPlayer(id: string): Promise<EventPlayer[]> {
        return this.repo.ListPlayers(id)
    }

    async ImportPlayer(id: string, data: EventPlayer[]): Promise<EventPlayer[]> {
        return this.repo.ImportPlayers(id, data)
    }

    async SyncData(eventId: string): Promise<void> {
        return this.repo.SyncData(eventId)
    }
}