import { Button, Flex, Form, message, Popconfirm, Table, TableColumnsType, Typography } from 'antd'
import Search from 'antd/es/input/Search'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDeletePlayer, useListPlayer } from '../hooks/playerCustomHook'
import { Player } from '../domain/players/model'
import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, DeleteOutlined, FormOutlined } from '@ant-design/icons'
const { Text } = Typography

function PlayerScreen() {
  const { list, loading: loadingList, Refresh } = useListPlayer()
  const { Delete } = useDeletePlayer()
  const [data, setData] = useState(list)
  const [loading, setLoading] = useState(loadingList)

  const confirm = (data: Player) => {
    setLoading(true)
    Delete(data).then(() => {
      return Refresh()
    }).catch((err) => {
      message.error(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  const columns: TableColumnsType<Player> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Player) => record.getFullname(),
      sorter: {
        compare: (a: Player, b: Player) => a.getFullname().localeCompare(b.getFullname()),
        // multiple: 1,
      },
      defaultSortOrder: 'ascend'
      // sortOrder: 'ascend',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10rem',
      render: (status: number) => status === 1 ? (<Text type="warning" strong><ClockCircleFilled /> Pending</Text>) :
        status === 2 ? (<Text style={{ color: '#1677FF', fontWeight: 'bold' }}><CheckCircleFilled /> Approved</Text>) : <Text type="secondary" strong><CloseCircleFilled /> Rejected</Text>,
      sorter: {
        compare: (a: Player, b: Player) => a.status - b.status,
        // multiple: 2,
      },
      // sortOrder: 'ascend',
    },
    {
      title: 'Tools',
      dataIndex: '',
      key: '',
      width: '8rem',
      render: (text: string, data: Player) => (
        <Flex justify='space-between'>
          <Link to={`/events/players/${data.id}`}>
            <Button type='primary'><FormOutlined /></Button>
          </Link>
          <Popconfirm
            title={`Delete ${data.getFullname()}?`}
            description="Are you sure you want to delete this player?"
            onConfirm={() => {
              confirm(data)
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type='primary' danger><DeleteOutlined /></Button>
          </Popconfirm>

        </Flex>
      ),
    },
  ]

  const onSearch = (search: string) => {
    if (search.length) {
      setData(list.filter((e: Player) => e.name.toLowerCase().includes(search.toLowerCase()) || e.surname.toLowerCase().includes(search.toLowerCase())))
    } else {
      setData(list)
    }
  }
  useEffect(() => {
    if (!loadingList) {
      setData(list)
      setLoading(false)
    }
  }, [loadingList, list])

  return (
    <>
      <Flex gap={10} justify={'right'}>
        <Form.Item>
          <Search placeholder="input search text" onSearch={onSearch} style={{ minWidth: 300 }} />
        </Form.Item>
        <Link to='/events/players/create'>
          <Button type='primary'>Add New</Button>
        </Link>
      </Flex>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey={'id'}
        pagination={{ defaultPageSize: 10 }}
        showHeader={true}
      ></Table >
    </>
  )
}

export default PlayerScreen