import { Event } from "../events/model";
import { Player } from "../players/model";

export class CheckInPlayer {
    id: string;
    fullname: string;
    name: string;
    surname: string;
    constructor(data?: {
        id: string,
        name: string,
        surname: string,
    }) {
        this.id = data?.id ? data.id : ''
        this.fullname = data?.name && data?.surname ? `${data.name} ${data.surname}` : ''
        this.name = data?.name ? data.name : ''
        this.surname = data?.surname ? data.surname : ''
    }

    setFullname(name: string, surname: string) {
        this.name = name
        this.surname = surname
        this.fullname = name + ' ' + surname
    }

    getFullname(): string {
        return (this?.name || '') + ' ' + (this?.surname || '')
    }

    getName(): string {
        return this.name
    }

    getSurname(): string {
        return this.surname
    }
}

export class CheckInEvent {
    id: string;
    name: string;
    constructor(data?: {
        id: string,
        name: string,
    }) {
        this.id = data?.id ? data.id : ''
        this.name = data?.name ? data.name : ''
    }
}

export class CheckIn {
    id: string;
    player: CheckInPlayer;
    event: CheckInEvent;
    playDate: Date;
    group: number;
    hole: number;
    caddie: string;
    status: number;
    createdAt: Date;
    checked: boolean;
    isCaptain: boolean;
    order: number;

    constructor(data?: {
        id: string,
        player?: Player,
        event?: Event,
        playDate: Date,
        group: number,
        hole: number,
        caddie: string,
        status: number,
        createdAt: Date,
        checked: boolean,
        order: number,
        isCaptain: boolean,
    }) {
        this.id = data?.id ? data.id : ''
        this.playDate = data?.playDate ? data.playDate : new Date()
        this.group = data?.group ? data.group : 0
        this.hole = data?.hole ? data.hole : 0
        this.caddie = data?.caddie ? data.caddie : ''
        this.status = data?.status ? data.status : 1
        this.player = new CheckInPlayer()
        this.event = new CheckInEvent()
        this.createdAt = data?.createdAt ? data.createdAt : new Date()
        this.checked = data?.checked ? data.checked : false
        this.isCaptain = data?.isCaptain ? data.isCaptain : false
        this.order = data?.order ? data.order : 0

        if (data?.player) {
            this.setPlayer(data.player)
        }

        if (data?.event) {
            this.setEvent(data.event)
        }
    }

    setPlayer(player: Player) {
        this.player = new CheckInPlayer({
            id: player.id,
            name: player.name,
            surname: player.surname,
        })
    }

    setEvent(event: Event) {
        this.event = new CheckInEvent({
            id: event.id,
            name: event.name,
        })
    }

    isPending(): boolean {
        return this.status === 1
    }

    isApproved(): boolean {
        return this.status === 2
    }

    isRejected(): boolean {
        return this.status === 3
    }

}