import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormCheckIn } from '../hooks/checkinCustomHook'
import { useListEvent } from '../hooks/eventCustomHook'
import { useListPlayer, useSavePlayer } from '../hooks/playerCustomHook'
import LoadingScreen from './LoadingScreen'
import { Button, DatePicker, Flex, Form, Input, message, Modal, Radio, Select, TimePicker } from 'antd'
import { CheckIn } from '../domain/checkins/model'
import { Player } from '../domain/players/model'
import { Event } from '../domain/events/model'
import { DeleteOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const AddPlayerModal = ({ open, data, onSubmit, onClose }: React.PropsWithChildren<{ data?: Player, open: boolean, onSubmit?: (player: Player) => Promise<void>, onClose: () => void }>) => {
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    name: data?.name || '',
    surname: data?.surname || ''
  })

  const { Save } = useSavePlayer()

  const handleSubmit = () => {
    setLoading(true)

    const player = new Player({
      id: data?.id || '',
      name: form.name,
      surname: form.surname,
      status: data?.status || 1
    })

    Save(player).then(() => {
      if (onSubmit) {
        return onSubmit(player)
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    setForm({
      name: data?.name || '',
      surname: data?.surname || ''
    })
  }, [data])

  return (
    <Modal loading={loading} title="Update player" onOk={handleSubmit} open={open} onClose={handleClose} onCancel={handleClose} destroyOnClose>
      <Form layout='vertical'>
        <Form.Item label="Name">
          <Input type='text' value={form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
        </Form.Item>
        <Form.Item label="Surname">
          <Input type='text' value={form.surname} onChange={(e) => setForm({ ...form, surname: e.target.value })} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

function CheckInFormScreen() {
  const { id } = useParams()
  const isEdit = (id ? id !== 'create' : false)
  const navigate = useNavigate()
  const { list: events, loading: eventLoading } = useListEvent()
  const { list: players, loading: playerLoading } = useListPlayer()
  const { data, loading, Save, Delete } = useFormCheckIn(id ? id === 'create' ? '' : id : '')
  const [formLoading, setFormLoading] = useState(false)
  const [event, setEvent] = useState<Event>()
  const [player, setPlayer] = useState<Player>()
  const [openModal, setOpenModal] = useState(false)
  const [playerList, setPlayerList] = useState<Player[]>(players)

  const [form, setForm] = useState({
    eventId: '',
    playerId: '',
    playDate: dayjs().minute(0).second(0),
    group: 0,
    hole: 0,
    caddie: '',
    status: 1,
    isCaptain: false,
    order: 0
  })

  const handleSelectEvent = (id: string) => {
    const event = events.find((e) => e.id === id)
    if (event) {
      setForm({ ...form, group: 1, hole: 1, eventId: id })
      setEvent(event)
    }
  }

  const handleSavePlayer = async (player: Player) => {
    setPlayerList(playerList.map((p) => {
      if (p.id === player.id) {
        return player
      }
      return p
    }))
    setOpenModal(false)
  }

  const handleSelectPlayer = (id: string) => {
    const player = players.find((p) => p.id === id)
    if (player) {
      setForm({ ...form, playerId: id })
      setPlayer(player)
    }
  }

  const handleSubmit = () => {
    setFormLoading(true)

    const checkIn = new CheckIn({
      id: data.id,
      playDate: form.playDate.toDate(),
      group: form.group,
      hole: form.hole,
      caddie: form.caddie,
      status: form.status,
      createdAt: data.createdAt,
      checked: data.checked,
      isCaptain: form.isCaptain,
      order: form.order,
    })
    if (event) {
      checkIn.setEvent(event)
    }
    if (player) {
      checkIn.setPlayer(player)
    }

    Save(checkIn).then(() => {
      navigate('/events/checkins')
    }).catch((err) => {
      console.error(err.message);
    }).finally(() => {
      setFormLoading(false)
    })
  }

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this check-in?')) {
      Delete().then(() => {
        navigate('/events/checkins')
      }).catch((err) => {
        message.error(err)
      })
    }
  }

  const handleRoleChange = (isCaptain: boolean) => {
    if (isCaptain) {
      setForm({ ...form, isCaptain, order: 0 })
    } else {
      setForm({ ...form, isCaptain })
    }
  }

  useEffect(() => {
    if (!loading && !eventLoading && !playerLoading) {
      setForm({
        eventId: data.event.id,
        playerId: data.player.id,
        playDate: dayjs(data.playDate).minute(0).second(0),
        group: data.group,
        hole: data.hole,
        caddie: data.caddie,
        status: data.status,
        order: data.order,
        isCaptain: data.isCaptain,
      })

      if (data.id) {
        setPlayer(playerList.find((p) => p.id === data.player.id))
        setEvent(events.find((e) => e.id === data.event.id))
      }
    }
  }, [loading, data, events, playerList, eventLoading, playerLoading])

  useEffect(() => {
    setPlayerList(players)
  }, [players])

  if (loading || eventLoading || playerLoading) {
    return (<LoadingScreen />)
  }

  return (
    <Form onFinish={handleSubmit} layout='vertical'>
      <Flex justify='space-between'>
        <Flex vertical style={{ minHeight: 200, width: '80%' }}>
          <Flex align='flex-end'>
            <Form.Item label='Event' required style={{ width: '100%' }}>
              <Select
                disabled={isEdit}
                showSearch
                placeholder="Select Event"
                value={form.eventId}
                defaultValue={form.eventId}
                optionFilterProp="label"
                onChange={handleSelectEvent}
                options={events.map((e: Event) => ({ value: e.id, label: e.name }))}
              />
            </Form.Item>
          </Flex>
          <Flex align='flex-end' justify='space-between' gap={20}>
            <Form.Item label='Player' required style={{ width: '100%' }}>
              <Flex gap={20} justify='space-between'>
                <Select
                  disabled={isEdit}
                  showSearch
                  placeholder="Select Player"
                  value={form.playerId}
                  optionFilterProp="label"
                  onChange={handleSelectPlayer}
                  options={playerList.map((e: Player) => ({ value: e.id, label: e.getFullname() }))}
                />
                {isEdit && (<Button onClick={() => setOpenModal(true)}>Update Player</Button>)}
              </Flex>
            </Form.Item>
            <Form.Item label='Play Date' required style={{ width: '100%' }}>
              <DatePicker
                value={form.playDate}
                style={{ width: '100%' }}
                inputReadOnly={true}
                allowClear={false}
                onChange={(date) => setForm({ ...form, playDate: date })}
              />
            </Form.Item>
          </Flex>
          <Flex align='flex-end' justify='space-between' gap={20}>
            <Form.Item label='Group' required style={{ width: '100%' }}>
              <Select value={form.group} disabled={!event} onChange={(e) => { setForm({ ...form, group: e }) }}>
                {event ?
                  new Array(event.getTotalGroup()).fill(0).map((_, i) => <Select.Option key={i + 1} value={i + 1}>Group {i + 1}</Select.Option>)
                  : (<Select.Option value={0}>Please Select Event</Select.Option>)}
              </Select>
            </Form.Item>
            <Form.Item label='Hole' required style={{ width: '100%' }}>
              <Select value={form.hole} disabled={!event} onChange={(e) => { setForm({ ...form, hole: e }) }}>
                {event ?
                  new Array(event.getTotalHole()).fill(0).map((_, i) => <Select.Option key={i + 1} value={i + 1}>Hole {i + 1}</Select.Option>)
                  : (<Select.Option value={0}>Please Select Event</Select.Option>)}
              </Select>
            </Form.Item>
          </Flex>
          <Flex align='flex-end' justify='space-between' gap={20}>
            <Form.Item label='Caddie No.' style={{ width: '100%' }}>
              <Input type='number' placeholder='Caddie No.' value={form.caddie} onChange={(e) => setForm({ ...form, caddie: e.target.value })} />
            </Form.Item>
            <Form.Item label='Play Date' required style={{ width: '100%' }}>
              <TimePicker
                value={form.playDate}
                inputReadOnly={true}
                format='HH:mm'
                allowClear={false}
                minuteStep={10}
                style={{ width: '100%' }}
                onChange={(date) => setForm({ ...form, playDate: date })}
                needConfirm={false}
              />
            </Form.Item>
          </Flex>
          <Flex align='flex-end' justify='space-between' gap={20}>
            <Form.Item label='Golfer' style={{ width: '100%' }}>
              <Radio.Group onChange={(e) => handleRoleChange(e.target.value)} value={form.isCaptain}>
                <Radio value={true}>Professional</Radio>
                <Radio value={false}>Amateur</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label='Order' required style={{ width: '100%' }}>
              <Select value={form.order} disabled={!event || form.isCaptain} onChange={(e) => { setForm({ ...form, order: e }) }}>
                {event ?
                  new Array(event.getTotalMember()).fill(0).map((_, i) => <Select.Option key={i} value={i}>{i + 1}</Select.Option>)
                  : (<Select.Option value={null}>Please Select Event</Select.Option>)}
              </Select>
            </Form.Item>

          </Flex>
          <Flex align='flex-end'>
            <Form.Item label='Status' style={{ width: '100%' }}>
              <Radio.Group onChange={(e) => setForm({ ...form, status: e.target.value })} value={form.status}>
                <Radio value={1}>Pending</Radio>
                <Radio value={2}>Check In</Radio>
                <Radio value={3}>Cancelled</Radio>
              </Radio.Group>
            </Form.Item>
          </Flex>
          <Flex align='flex-end' justify='space-between' gap={20}>
            <Form.Item style={{ width: '100%' }}>
              <Button type='primary' htmlType='submit' loading={formLoading}>Save</Button> <Button type='default' onClick={() => window.history.back()}>Back</Button>
            </Form.Item>
          </Flex>
        </Flex>
        {!loading && data.id ? (
          <Flex vertical justify='flex-start'>
            <Button type='default' danger onClick={handleDelete}><DeleteOutlined /></Button>
          </Flex>
        ) : (<></>)
        }

      </Flex>
      <AddPlayerModal open={openModal} data={player} onSubmit={handleSavePlayer} onClose={() => setOpenModal(false)} />
    </Form>
  )
}

export default CheckInFormScreen